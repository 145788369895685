@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

.pdfView {
  min-width: 49%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background-color: #515659;
  border: 1px solid #3c3838;
  margin: 5px;
  .container {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px 0px;
    width: max-content;
    margin: 0 auto;
    overflow: scroll;
    box-sizing: border-box;
    background-color: white;
    margin-top: 35px;
    margin-bottom: 15px;
    :global(.react-pdf__Page__textContent), :global(.react-pdf__Page__annotations) {
      display: none;
    }
  }

  .pageTool {
    position: absolute;
    display: flex;
    align-items: center;
    background: rgb(49 54 57);
    color: white;
    width: -webkit-fill-available;
    top: 0;
    padding: 5px 10px;
    .title {
      width: 200px;
    }

    .input {
      :global(.ant-input-number) {
        width: 24px;
        background-color: #191b1c;
        border-color: #191b1c;
        input {
          color: white;
        }
      }
    }
    .pageToolItem {
      padding: 8px 15px;
      padding-left: 10px;
      cursor: pointer;
    }

    :global(.ant-input-number-group-wrapper) {
      :global(.ant-input-number-group-addon) {
        border: 0;
        color: white;
      }
      :global(.ant-input-number) {
        width: 53px;
        background-color: #191b1c;
        border-color: #191b1c;
        input {
          color: white;
        }
      }
    }
    :global(.ant-divider-vertical) {
      border-inline-start: 1px solid #5d6264;
    }
  }
}
