.loginModal {
  width: 450px !important;
  :global(.ant-modal-content) {
    padding: 30px 50px 40px;
    :global(.ant-modal-header) {
      text-align: center;
      margin-bottom: 30px;
      :global(.ant-modal-title) {
        font-size: 20px;
      }
    }
    :global(.ant-form-item-control-input .ant-input-wrapper:nth-child()) {
      border-radius: 0;
    }
    :global(.ant-btn-primary) {
      width: 100%;
      background-color: #9ac0f1;
      border-radius: 0;
      color: white;
    }
    :global(.ant-modal-footer) {
      > button {
        width: 100%;
        height: 35px;
        background-color: #9ac0f1;
        border-radius: 0;
        margin: 0 !important;
      }
    }
  }
}
