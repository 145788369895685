@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

@mixin flexStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analyze {
  min-width: 49%;
  > div {
    margin: 5px;
    border: 1px solid #3c3838;
    overflow-y: scroll;
    text-align: start;
    height: 93%;
    :global(.ant-spin-nested-loading) {
      height: 100%;
      :global(.ant-spin) {
        max-height: none;
      }
    }
    .details {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      > div {
        width: 98%;
        margin: 1%;
      }
    }
    :global(.ant-collapse-item) {
      margin-bottom: 20px;
      border: 1px solid $border !important;
      border-radius: 5px !important;
      :global(.ant-collapse-header:hover) {
        color: #ac8730;
      }
    }
    > div {
      padding: 10px;
    }
    > h2 {
      padding-left: 10px;
      position: relative;
      > :global(.anticon) {
        position: absolute;
        top: calc(50% - 12px);
        font-size: 24px;
      }
    }
    .isOk {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      color: $articleHint;
      font-size: 18px;
      font-weight: 500;
      :global(.ant-select) {
        min-width: 140px;
        margin-left: 3px;
        :global(.ant-select-selector) {
          border: 1px solid #8e3700;
          background-color: #d8e3f5;
          &:hover {
            border: 1px solid #8e3700 !important;
          }
          :global(.ant-select-selection-item) {
            color: #d10000;
            font-weight: 600;
          }
        }
        :global(.ant-select-arrow) {
          color: #7c006c;
        }
      }
    }
    .evaluate {
      border: 1px solid #112c54;
      border-radius: 10px;
      background-color: #fff5f4;
      padding: 0 15px 15px;
      margin: 30px 10px;
      > p {
        color: $articleHint;
      }
    }
    
  }
}

.vditor {
  border: none !important;
}
