@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

// 灰色按钮
@mixin btnStyle {
  :global(.ant-btn) {
    background-color: #d0cece;
    color: #3c3838;
    border: 1px solid #112c54;
    border-radius: 5px;
    &:hover {
      background-color: $main;
      color: white !important;
    }
  }
}

.subject {
  @include mainCon();
  .loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .noneData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    > h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include btnStyle();
  }
  :global(.ant-table-wrapper) {
    background-color: #7c006d;
    border: 1px solid #3c3838;
    margin: 20px 55px;
    :global(.ant-table-container) {
      border: 0 !important;
      :global(.ant-table-header .ant-table-thead > tr > th) {
        background-color: $main;
        color: white;
        border-right-width: 0 !important;
      }
      :global(.ant-table-tbody > tr > td.ant-table-cell-row-hover) {
        background-color: #ffaeb8 !important;
      }
      :global(.ant-table-cell) {
        background-color: #fff4f5;
        @include downInput();
      }
      :global(.ant-table-row-selected) {
        :global(.ant-table-cell) {
          background-color: #ffaeb8 !important;
        }
      }
    }
  }

  .articleAnalysis {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 209px);
    .box {
      min-width: 49%;
      margin: 5px;
      border: 1px solid #3c3838;
      overflow-y: scroll;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      :global(.ant-spin-nested-loading) {
        width: 100px;
        :global(.ant-spin-text) {
          padding-top: 20px !important;
        }
      }
    }
  }
  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    font-size: 22px;
    font-weight: 500;
    > div {
      display: flex;
      align-items: center;
      @include btnStyle();
      :global(.ant-btn) {
        margin-left: 10px;
      }
    }
    .titDetail {
      font-size: 15px;
      margin-left: 10px;
      margin-right: 10px;
      display: grid;
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
