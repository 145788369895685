@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

.modalStyle {
  :global(.ant-modal-content) {
    min-width: calc(100vw - 255px);
  }
  :global(.ant-modal) {
    width: auto !important;
  }
  :global(.ant-table-wrapper) {
    background-color: #7c006d;
    border: 1px solid #3c3838;
    margin: 20px 55px;
    :global(.ant-table-container) {
      border: 0 !important;
      :global(.ant-table-header .ant-table-thead > tr > th) {
        background-color: $main;
        color: white;
        border-right-width: 0 !important;
      }
      :global(.ant-table-tbody > tr > td.ant-table-cell-row-hover) {
        background-color: #ffaeb8 !important;
      }
      :global(.ant-table-cell) {
        background-color: #fff4f5;
        :global(.ant-space) {
          height: 28px;
          border: 1px solid #8e3700;
          padding: 2px 1px;
          background-color: #d8e3f5;
          border-radius: 7px;
          color: #d10000;
          font-weight: 600;
          min-width: 78px;
          justify-content: end;
          :global(.ant-space-item) {
            width: 100%;
            padding: 5px 0;
            .flexCon {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              .downInput {
                width: 72%;
              }
              :global(.anticon) {
                background-color: $main;
                color: #e4e6ea;
                border-radius: 3px;
                font-size: 17px;
              }
            }
          }
        }
      }
      :global(.ant-table-row-selected) {
        :global(.ant-table-cell) {
          background-color: #ffaeb8 !important;
        }
      }
    }
  }
}
