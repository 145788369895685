@import "./color.scss";

@mixin mainCon {
  width: 100%;
  text-align: center;
  // height: calc(100% - 103px);
  height: calc(100% - 39px);
  position: absolute;
  top: 40px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

@mixin downInput {
  :global(.ant-space) {
    height: 28px;
    border: 1px solid #8e3700;
    padding: 2px 1px;
    background-color: #d8e3f5;
    border-radius: 7px;
    color: #d10000;
    font-weight: 600;
    min-width: 85px;
    justify-content: end;
    :global(.ant-space-item) {
      width: 100%;
      padding: 5px 0;
      .flexCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .downInput {
          width: 72%;
        }
        :global(.anticon) {
          background-color: $main;
          color: #e4e6ea;
          border-radius: 3px;
          font-size: 17px;
        }
      }
    }
  }
}
