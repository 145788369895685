@import "~/styles/color.scss";
@import "~/styles/reusable.scss";
.uploadArticle {
  @include mainCon();
  width: 80%;
  left: 10%;
  :global(.ant-tabs) {
    margin-top: 15px;
  }
  :global(.ant-tabs),
  :global(.ant-table-content),
  :global(.ant-tabs .ant-tabs-tab),
  :global(.ant-upload-drag) {
    font-size: 18px;
  }
  .manuscript {
    text-align: start;
    > p {
      > span {
        color: dimgrey;
      }
    }
    :global(.ant-upload) {
      background-color: #f0f2f6;
      border: none;
      border-radius: 15px;
    }
    :global(.ant-upload-drag-container) {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      align-items: center;
      > div {
        display: flex;
        > span {
          font-size: 39px;
          margin-right: 15px;
          color: #94a6c6;
        }
        > div {
          text-align: start;
        }
      }
    }

    .bot {
      display: flex;
      margin-top: 15px;
      align-items: center;
      > input {
        width: 500px;
        margin-left: 20px;
      }
    }
    :global(.ant-table-wrapper) {
      margin-top: 20px;
      display: block;
    }
  }
  .doiUpload {
    text-align: start;
    > p {
      > span {
        color: dimgrey;
      }
    }
    .bot {
      > input {
        margin-right: 10px;
      }
    }
    :global(.ant-table-wrapper) {
      margin: 20px 0 40px;
      display: block;
    }
    :global(.ant-space) {
      color: #1f1f1f;
      margin-top: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 7px 11px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
    }
    :global(.ant-select) {
      width: 100px;
    }
  }
  .manuscript,
  .doiUpload {
    > div {
      display: flex;
      align-items: center;
      :global(.ant-select) {
        width: 100px;
      }
    }
  }
}

.journalTxt {
  white-space: nowrap;
  display: inline-block;
  > a {
    color: #0265d6;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.btn1 {
  position: fixed;
  top: 95%;
  width: 80%;
  background-color: white;
  padding-top: 10px;
  display: flex;
  justify-content: end;
  z-index: 50;
}
