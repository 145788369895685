@import "~/styles/color.scss";
@import "~/styles/reusable.scss";

@mixin flexStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
// 圆环
.interaction {
  margin: 0 auto;
  @include flexStyle;
  padding: 70px 20px;
  // 三层圆环
  .ring {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 1px dashed black;
    position: relative;
    .innerCircle {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 1px dashed black;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .center {
        width: 80px;
        height: 80px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid black;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include flexStyle;
        color: #3871ca;
        font-weight: 600;
        transition: all 0.7s ease 1s;
        cursor: pointer;
        &:hover {
          width: 85px;
          height: 85px;
        }
      }
      .inner {
        &:nth-of-type(2) {
          transform: rotate(90deg);
          transform-origin: 20px 120px;
          > span {
            transform: rotate(269deg);
          }
        }
        &:nth-of-type(3) {
          transform: rotate(180deg);
          transform-origin: 20px 120px;
          > span {
            transform: rotate(180deg);
          }
        }
        &:nth-of-type(4) {
          transform: rotate(270deg);
          transform-origin: 20px 120px;
          > span {
            transform: rotate(90deg);
          }
        }
      }
    }
    .outer {
      &:nth-of-type(2) {
        transform: rotate(45deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(315deg);
        }
      }
      &:nth-of-type(3) {
        transform: rotate(90deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(270deg);
        }
      }
      &:nth-of-type(4) {
        transform: rotate(135deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(227deg);
        }
      }
      &:nth-of-type(5) {
        transform: rotate(180deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(180deg);
        }
      }
      &:nth-of-type(6) {
        transform: rotate(225deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(135deg);
        }
      }
      &:nth-of-type(7) {
        transform: rotate(270deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(90deg);
        }
      }
      &:nth-of-type(8) {
        transform: rotate(315deg);
        transform-origin: 20px 220px;
        > span {
          transform: rotate(45deg);
        }
      }
    }
    .inner,
    .outer {
      width: 50px;
      height: 50px;
      position: absolute;
      background-color: white;
      border: 1px solid black;
      border-radius: 50%;
      text-align: center;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      @include flexStyle();
      transition: all 0.7s ease 1s;
      cursor: pointer;
      &:hover {
        width: 60px;
        height: 60px;
      }
    }
  }
}
