@import "~/styles/color.scss";

.head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;

  > div {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
      width: 130px;
      margin: 5px 0;
    }

    .right {
      display: flex;
      align-items: center;
      .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        > img {
          width: 35px;
          margin-right: 5px;
        }
      }
    }
  }
}
