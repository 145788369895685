@import "~/styles/color.scss";
@import "~/styles/reusable.scss";
.home {
  @include mainCon();
  text-align: start;
  > img {
    width: 100%;
  }
  :global(.ant-typography) {
    padding: 20px;
    font-size: 22px;
    margin-top: 20px;
  }
  .link {
    color: #0070c6 !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
