@import "./styles/color.scss";

html {
  font-size: 18px;

  body {
    padding: 0;
    margin: 0;
    width: fit-content;
    min-width: 100vw;
    color: $fontColorMain;
    font-size: 14px;
    font-family: -apple-system, Helvetica Neue, Arial, sans-serif,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol,
      noto color emoji;
    background: $grey;
    overflow: auto;

    .root {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        color: $fontColorMain;
      }

      ul {
        list-style: none;
      }
    }
  }
}

// 滚动条
// ::-webkit-scrollbar {
//   width: 0 !important; /* remove scrollbar space */
//   background: transparent; /* optional: just make scrollbar invisible */
//   -ms-overflow-style: none;
//   overflow: -moz-scrollbars-none;
// }
